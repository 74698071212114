<template>
  <Split class="VideoPollingWrapper"
    direction="row"
    :sideOffset="0.2">
    <template #first>
      <RealTimeSide onlyVideo :isCustomization="isCustomization"
        @vehicle-check="onVehicleCheck"
        @vehicle-check-all="onVehicleCheckAll"></RealTimeSide>
    </template>
    <template #second>
      <VpPlayer :videoList="videoList" ref="VpPlayer" :videoGridOptions="videoGridOptions" :isCustomization="isCustomization"></VpPlayer>
    </template>
  </Split>
</template>

<script>
import Split from '@/components/Split';
import RealTimeSide from '@/components/RealTimeSide';
import VpPlayer from './VpPlayer.vue';
import { getTreeNodeId } from '@/utils/treeHelper';


export default {
  // `name`与路由保持一致
  name: 'VideoPolling',
  components: {
    Split,
    RealTimeSide,
    VpPlayer
  },
  props: {
    isCustomization: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      checkedList: [],
      videoGridOptions: null,
      definitionPlayTime: 0,
      outTime: null,
    };
  },
  computed: {
    videoList() {
      const { checkedList } = this;
      return checkedList
        .filter(obj => [0,1, 2, 3].includes(obj.Z) && obj.ACC == 0)
        .map(obj => this.toChannels(obj))
        .flat();
    }
  },
  watch: {
    videoList: {
      handler(v) {
        if (v.length == 0 && this.checkedList.length) {
          this.$refs?.VpPlayer?.stopPolling();
          clearInterval(this.outTime);
          this.outTime = setInterval(()=>{
            this.$message.error('所有设备均已离线')
          }, 30000)
        }else {
          clearInterval(this.outTime)
        }
      }
    },
    isCustomization: {
      handler(v) {
        if (v) {
          this.videoGridOptions = [{ value: 4, label: '2行x2列' },{ value: 16, label: '4行x4列' },]
        }
      },
      immediate: true
    }
  },
  methods: {
    onVehicleCheck(data, checked, node, allCheckeds) {
      // 车辆
      if (data.nodeType === 2) {
        this.checkedList = allCheckeds;
      }
    },
    onVehicleCheckAll(checked, allCheckeds) {
      this.checkedList = allCheckeds;
    },
    toChannels(data) {

      const channels = this.parseChannels(data.camreaLine);
      const cameraCH = this.parseChannels(data.cameraCH);

      // 终端号
      const device = data.mediaTerminalNo;
      // 设备协议类型 1: GF-1078, GB-1078; 2: GA系列
      // const protocolType = ['GF-1078', 'GB-1078'].includes(data.T) ? 1 : 2;
      const protocolType = data.T.indexOf("GA-") < 0
        ? 1
        : 2;
      // 特殊协议 0: 不处理; 1: 粤标
      const SpecialSign = data.percentageOfFlow === 6 ? 1 : 0;
      // 设备名称
      const plate = data.P;
      // 车辆Id
      const vehicleId = data.V;
      // 车组Id
      const groupId = data.M;

      return channels
        .map((channel,ind) => {
          const key = getTreeNodeId(data.M, data.V, channel);
          return {
            key,
            channel,
            channelsName: cameraCH[ind],
            device,
            protocolType,
            SpecialSign,
            plate,
            vehicleId,
            groupId
          };
        });
    },
    parseChannels(channelStr) {
      try {
        return JSON.parse(channelStr);
      } catch (err) {
        //
      }
      return [];
    },

  }
}
</script>

<style lang="scss" scoped>
.VideoPollingWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>